// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-bg{
    position:relative;
  
}
.search__component{
    max-width: 783px;
    width:100%;
    min-height: 80px;
    background: #fff;
    display: flex;
    align-items: center;
    padding:16px;
    gap: 16px;
    border-radius: 8px;
    margin: 0 auto;
    margin-top:36px;

}
.search__component .input_search{
    flex: 1 1;
    display: flex;
    align-items: center;
    gap:8px;
}
.search__component input,
.search__component input::placeholder{  
    flex: 1 1;
    outline: none;
    color: var(--gray-400, #98A2B3);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.storybook-tagline-flexline-container h4{
    color: #101828!important;
    font-family: Inter;
    font-size: 20px!important;
    font-style: normal!important;;
    font-weight: 500;
    line-height: 30px; 
    margin: 0!important;
}
.storybook-tagline-flexline-container h2 {
    color: var(--gray-500, #667085);
/* Text lg/Normal */
font-family: Inter;
font-size: 18px!important;
font-style: normal;
font-weight: 400;
line-height: 28px; 
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;;AAErB;AACA;IACI,gBAAgB;IAChB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,SAAS;IACT,kBAAkB;IAClB,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,OAAO;AACX;AACA;;IAEI,SAAO;IACP,aAAa;IACb,+BAA+B;IAC/B,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;AAChC;AACA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,yBAAyB;IACzB,4BAA4B;IAC5B,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,+BAA+B;AACnC,mBAAmB;AACnB,kBAAkB;AAClB,yBAAyB;AACzB,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB","sourcesContent":[".hero-bg{\n    position:relative;\n  \n}\n.search__component{\n    max-width: 783px;\n    width:100%;\n    min-height: 80px;\n    background: #fff;\n    display: flex;\n    align-items: center;\n    padding:16px;\n    gap: 16px;\n    border-radius: 8px;\n    margin: 0 auto;\n    margin-top:36px;\n\n}\n.search__component .input_search{\n    flex: 1;\n    display: flex;\n    align-items: center;\n    gap:8px;\n}\n.search__component input,\n.search__component input::placeholder{  \n    flex: 1;\n    outline: none;\n    color: var(--gray-400, #98A2B3);\n    font-family: Inter;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; /* 150% */\n}\n.storybook-tagline-flexline-container h4{\n    color: #101828!important;\n    font-family: Inter;\n    font-size: 20px!important;\n    font-style: normal!important;;\n    font-weight: 500;\n    line-height: 30px; \n    margin: 0!important;\n}\n.storybook-tagline-flexline-container h2 {\n    color: var(--gray-500, #667085);\n/* Text lg/Normal */\nfont-family: Inter;\nfont-size: 18px!important;\nfont-style: normal;\nfont-weight: 400;\nline-height: 28px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
