import useAuth from "./useAuth";
import axios from "../react-query/services/axios";

export default function useRefreshToken() {
  const newAuth = useAuth();
  const refresh = async () => {
    const response = await axios.post("/account/refresh_token", {
      withCredentials: true,
    });
    newAuth?.setAuth((prev:any) => {
      return {
        ...prev,
        accessToken: response?.data?.data?.accessToken,
      };
    });
    return response?.data?.data?.accessToken
    
  };
  return refresh;
}
